<template>
    <div id="app">
        <router-view/>
    </div>
</template>
<script>
export default {
    name: 'App',
    components: {
    },
    data() {
        return {}
    },
    created() {

    },
    mounted() {
        this.$store.commit('initData');
    },
    methods: {},
}
</script>
<style lang="scss" scoped>
@import "common/iconfont.css";
#app {
    width: 100vw;
    height: 100vh;
    background-color: #020308;
}
</style>
