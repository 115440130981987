import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import dataV from '@jiaminghi/data-view';
// 引入全局css
import './assets/scss/style.scss';
// 按需引入vue-awesome图标
import Icon from 'vue-awesome/components/Icon';
import 'vue-awesome/icons/chart-bar.js';
import 'vue-awesome/icons/chart-area.js';
import 'vue-awesome/icons/chart-pie.js';
import 'vue-awesome/icons/chart-line.js';
import 'vue-awesome/icons/align-left.js';

Vue.config.productionTip = false;


import config from './common/config.js'
import http  from './common/http.js'
Vue.prototype.$config = config
http.defaults.baseURL = config.baseUrl
Vue.prototype.$http = http
import Element from 'element-ui'
Vue.use(Element,{size:'small'});
import 'element-ui/lib/theme-chalk/index.css';

// 全局注册
Vue.component('icon', Icon);
Vue.use(dataV);

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app');
