import axios from 'axios';
import store from  '../store/index.js'
axios.interceptors.request.use(
  config => {
    if (store.state.$token) {
      config.headers.token = store.state.$token
    }
    return config
  },
  error => {
    return Promise.reject(error)
  }
)
axios.interceptors.response.use(
  response => {
    return response
  },
  error => {
    if (error.response) {
      // switch (error.response.status) {
      //   case 401:
      //     store.commit('clearToken')
      //     router.replace({
      //       path: '/login',
      //       query: { redirect: router.currentRoute.fullPath }
      //     })
      // }
    }


    return Promise.reject(error.response.data)
  }
)

export default axios;
