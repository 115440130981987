import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    $token: '',
    $farmUser: {
    },
    $farmInfo: {
    },
    $farmDetailInfo:{

    }
  },
  mutations: {
    setToken (state, token) {
      state.$token = token
      localStorage.setItem('token', token)
    },
    setFarmUser (state, farmUser) {
      state.$farmUser = farmUser
      localStorage.setItem('farmUser', JSON.stringify(farmUser))
    },
    setFarmInfo (state, farmInfo) {
      state.$farmInfo = farmInfo
      localStorage.setItem('farmInfo', JSON.stringify(farmInfo))
    },
    setFarmDetailInfo (state, farmDetailInfo) {
      state.$farmDetailInfo = farmDetailInfo
      localStorage.setItem('farmDetailInfo', JSON.stringify(farmDetailInfo))
    },
    clearToken (state) {
      state.$token = ''
      localStorage.removeItem('token')
    },
    initData (state) {
      state.$token = localStorage.getItem('token') || ''
     let  farmUser = localStorage.getItem('farmUser');
      if(farmUser){
        state.$farmUser = JSON.parse(farmUser)
      }
      let farmInfo = localStorage.getItem('farmInfo');
      if(farmInfo){
        state.$farmInfo = JSON.parse(farmInfo)
      }
      let farmDetailInfo = localStorage.getItem('farmDetailInfo');
      if(farmDetailInfo){
        state.$farmDetailInfo = JSON.parse(farmDetailInfo)
      }
    }

  },
  actions: {
  },
  modules: {
  }
})
