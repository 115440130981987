import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  { path: '/login', component: () => import('@/views/login'),
    name: 'login', meta: { title: '登录' } },
  {
  path: '/',
  name: 'index',
  component: () => import('../views/index.vue')
}]
const router = new VueRouter({
  routes
})

export default router
